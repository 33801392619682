.screenCoveredSpinner {
	position: fixed;
	z-index: 999;
	display: grid;
	margin-top: -100px;
	background-color: rgba(0, 0, 0, 0.5);
	place-items: center;
	inset: 0;

	.textsContainer {
		position: relative;
		display: inline-block;
	}

	.text1 {
		width: 100%;
		color: #b8b8b8;
	}

	.text2 {
		position: absolute;
		top: 0;
		left: 0;
		overflow: hidden;
		width: 0;
		color: rgb(255, 255, 255);
		white-space: nowrap;
		animation: stagger 3s ease infinite;
	}
}

@keyframes stagger {
	from { width: 0; }
	to { width: 100%; }
}
