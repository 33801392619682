.units {
	position: absolute;
	right: 0;
	width: 120px;
	padding: 8px 10px;
	background-color: white;
	box-shadow: rgba(0, 0, 0, 0.12) 0 1px 3px, rgba(0, 0, 0, 0.24) 0 1px 2px;
	list-style-type: none;
	font-weight: lighter;

	.unit {
		padding: 2px 0;
		font-size: 15px;

		&:hover {
			text-decoration: underline;
			cursor: pointer;
		}

		&.active {
			text-decoration: underline;
		}
	}
}
