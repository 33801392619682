@import '../../../utils/sass/mixins.scss';

$divider: 1px solid #979797;

.mainContainer {
	.flexColumn {
		@include flex(space-between, flex-start, column);

		height: 90px;

		.itemText {
			color: #4a4a4a;
			font-size: 20px;
		}
	}

	.propertyInfo {
		@include flex(flex-start, center, row);

		gap: 60px;
		padding: 0 0 30px;
		border-bottom: $divider;

		.propertyInfoItems {
			@include flex(flex-start, center, row);

			gap: 60px;
		}
	}

	.aboutText {
		line-height: 1.8;
	}

	.itemValue {
		font-weight: bold;
	}

	.recommendedContainer {
		border-top: $divider;
	}
}

.spinnerContainer {
	@include flex();

	min-height: 70vh !important;
}

@media (max-width: 768px) {
	.mainContainer .propertyInfo {
		flex-wrap: wrap;
		gap: 10px;

		.itemText {
			font-size: 16px;
		}

		.propertyInfoItems {
			gap: 20px;
		}
	}
}

@media (max-width: 510px) {
	.mainContainer {
		.flexColumn {
			height: auto;
		}

		.itemValue {
			font-size: 30px;
		}
	}
}
