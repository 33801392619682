.sectionHeading {
	margin-bottom: 24px;
	color: #000;
	font-size: 30px;
	font-family: Lato, sans-serif;
}

.sectionText {
	color: #4a4a4a;
	font-size: 18px;
	line-height: 30px;
}
