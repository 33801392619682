$native-link-color: #007bff;
$primary-red: #c32e2e;
$darker-red: #8a1414;
$lighter-red: #ea5c5c;
$background-red: #fa8f8f;
$primary-purple: #5817e0;
$darker-purple: #240071;
$lighter-purple: #7d43fa;
$background-purple: #ad87ff;
$primary-blue: #428eff;
$darker-blue: #0048b4;
$lighter-blue: #80b2ff;
$background-blue: #b2d0fc;
$primary-green: #5bcc96;
$darker-green: #00753d;
$lighter-green: #9bdbbc;
$background-green: #d0eddf;
$background-gradient: linear-gradient(to right, #a3c8ff, #cbd6f2);
$tab-item-default-bottom-border: 1px solid #d8d8d8;
$tab-item-default-background: #fff;
$tab-item-hover-bottom-border: 1px solid #4a4a4a;
$tab-item-hover-background: #f5f5f5;
$tab-item-active-bottom-border: 2px solid #000;
$tab-item-active-background: #fff;
$toggle-button-selected-background: #0048b4;
$toggle-button-selected-text: #fff;
$toggle-button-unselected-background: #fff;
$toggle-button-hover-unselected-background: #428eff;
$toggle-button-hover-unselected-text: #fff;
$toggle-button-unselected-text: #000;
$toggle-button-unselected-border: 1px solid #000;
$primary-hover: 1px 2px 16px 0 rgba(66, 142, 255, 0.4);
