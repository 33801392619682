@import '../../../utils/sass/mixins.scss';

.mainContainer {
	width: 70%;
	margin: 0 auto;
	padding-top: 4rem;
	text-align: center;
}

.copyField {
	@include flex(space-between);

	max-width: 250px;
	margin: 1rem auto;
	padding: 4px;
	border: 1px solid #dadada;
	border-radius: 4px;

	.copyText {
		width: 80%;
		padding: 0 10px;
		border: none;
		border-radius: 3px;
		color: #323232;
		outline: none;
		font-size: 14px;

		&.highlight {
			background-color: $background-green;
		}
	}

	.copyButton {
		margin: 0;
		padding-left: 10px;
		border: none;
		background-color: transparent;
		color: $darker-red;
		outline: none;
		font-size: 14px;

		&:disabled {
			pointer-events: none;
		}
	}
}

.desktopCollage {
	padding: 0;

	img {
		width: 100%;
	}
}

.mobileCollage {
	display: none;
	padding: 0;

	img {
		width: 100%;
	}
}

.socialIcons {
	@include flex();

	gap: 1rem;

	img {
		cursor: pointer;
		transition: all 0.2s ease-out;

		&:hover {
			filter: drop-shadow(0.1rem 0.2rem 0.1rem rgba(0, 0, 0, 0.2));
		}
	}
}

@include responsive(smallTablet) {
	.mainContainer {
		width: 100%;
	}

	.desktopCollage {
		display: none;
	}

	.mobileCollage {
		display: block;
		margin-top: 3rem;
	}
}
