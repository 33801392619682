@import '../../../utils/sass/mixins.scss';

.sectionHeading {
	color: #000;
	font-weight: 800;
	font-size: 38px;
}

.sectionSubHeading {
	margin: 24px 0;
	color: #000;
	font-weight: 600;
	font-size: 22px;
}

.sectionText {
	color: #4a4a4a;
	font-weight: 500;
	font-size: 18px;
	line-height: 30px;
}

@include responsive(smallTablet) {
	.sectionHeading {
		font-size: 36px;
	}

	.sectionSubHeading {
		font-size: 22px;
	}

	.sectionText {
		font-size: 16px;
	}
}
