@import '../../utils/sass/mixins.scss';

.leftColumn {
	padding: 5rem;
}

.rightColumn {
	padding: 4rem 5rem;
	background-color: #f5f5f5;

	.addressLine2 {
		margin-bottom: 1.4rem;
		color: #4a4a4a;
		font-weight: 500;
	}

	.priceContainer {
		@include flex(space-between);

		margin-bottom: 10px;
	}

	.count {
		font-size: 18px;
	}
}

@include responsive(smallTablet) {
	.leftColumn,
	.rightColumn {
		padding: 4rem 2rem !important;
	}
}

@include responsive(smallScreen) {
	.rightColumn .count {
		font-size: 16px;
	}
}
