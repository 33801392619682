@import '../../../../utils/sass/mixins.scss';

.imagesGrid {
	display: grid;
	grid-template-areas:
		'mainImage mainImage secondImage'
		'mainImage mainImage thirdImage';
	grid-auto-rows: 260px;
	gap: 14px;
	width: 100%;

	.seeAllPhotos {
		@include primaryButton();

		position: absolute;
		right: 18px;
		bottom: 18px;
		padding: 6px 14px;
		background-color: #fff;
		color: #000;
		font-size: 14px;
		cursor: pointer;

		&:hover {
			background: #000;
			color: #fff;
		}
	}

	.mainImage {
		position: relative;
		grid-area: mainImage;

		.seeAllPhotos {
			display: none;
		}
	}

	.secondImage {
		grid-area: secondImage;
	}

	.thirdImage {
		position: relative;
		grid-area: thirdImage;
	}

	img {
		object-fit: cover;
		width: 100%;
		height: 100%;
		border-radius: 4px;
		transition: opacity 0.2s ease-out;

		&:hover {
			opacity: 0.95;
			cursor: pointer;
		}
	}
}

@media (max-width: 768px) {
	.imagesGrid {
		grid-template-areas: 'mainImage';
		grid-auto-rows: 340px;

		.mainImage .seeAllPhotos {
			display: initial;
		}

		.secondImage,
		.thirdImage {
			display: none;
		}
	}
}

@media (min-width: 1600px) {
	.imagesGrid {
		grid-template-areas:
			'mainImage mainImage mainImage mainImage secondImage'
			'mainImage mainImage mainImage mainImage thirdImage';
		grid-auto-rows: 282px;
	}
}
