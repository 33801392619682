@import '../../../utils/sass/mixins.scss';

.checkboxButtons {
	@include flex(flex-start);

	gap: 10px;
	margin-bottom: 1.4rem;
}

.checkboxButton {
	@include flex(flex-start);

	gap: 14px;
	width: 100%;
	padding: 0.8rem 1rem;
	border: 1px solid #4a4a4a;
	border-radius: 5px;
	background-color: #fff;
	color: #000;
	outline: none !important;
	font-size: 18px;
	transition: background-color 0.2s ease;

	&.selected {
		border-color: #000;
		background-color: #dcdcdc;
	}

	&:hover {
		background-color: #f0f0f0;
	}
}

.minimumShareText {
	margin-top: -1rem;
	padding-bottom: 0.6rem;
	font-size: 14px !important;
}

.submitButtons {
	@include flex();

	gap: 10px;
	width: 94%;
	margin: 3rem auto 0;
}

.payableAmount {
	@include flex(space-between, flex-start);

	margin-top: 2rem;
	padding-bottom: 10px;
	border-bottom: 1px solid #cecece;
}

@include responsive(smallDevice) {
	.submitButtons {
		width: 100%;
	}

	.checkboxButtons {
		@include flex(flex-start, flex-start, column);
	}
}
