.modalDialog {
	position: relative;
	min-width: 50%;

	.modalContent {
		border-radius: 9px;
	}

	.modalBody {
		padding: 3rem 4rem;
	}
}

.crossIcon {
	position: absolute;
	top: 1rem;
	right: 1.4rem;
	cursor: pointer;
}

@media (max-width: 768px) {
	.modalDialog .modalBody {
		padding: 2rem;
	}
}
