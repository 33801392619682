@import './utils/sass/variables.scss';

body {
	overflow-x: hidden !important;
	margin: 0;
	font-family:
		Lato,
		-apple-system,
		BlinkMacSystemFont,
		'Segoe UI',
		Roboto,
		Oxygen,
		Ubuntu,
		Cantarell,
		'Fira Sans',
		'Droid Sans',
		'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family:
		source-code-pro,
		Menlo,
		Monaco,
		Consolas,
		'Courier New',
		monospace;
}

body,
html {
	scroll-behavior: smooth;
}

p {
	color: #4a4a4a;
	font-size: 16px !important;
}

h1 {
	font-weight: bold;
	font-size: 38px !important;
}

h2 {
	font-weight: bold;
	font-size: 30px !important;
}

h3 {
	font-size: 25px !important;
}

h4 {
	font-weight: 600;
	font-size: 22px !important;
}

h5 {
	font-size: 20px !important;
}

.caption {
	font-size: 16px !important;
}

@media (max-width: 767px) {
	p {
		font-size: 16px !important;
	}

	h1 {
		font-size: 26px !important;
	}

	h2 {
		font-size: 22px !important;
	}

	h3 {
		font-size: 20px !important;
	}

	h4 {
		font-size: 18px !important;
	}

	h5 {
		font-size: 17px !important;
	}

	.caption {
		font-size: 14px !important;
	}
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	margin: 0;
	appearance: none;
}

input[type='number'] {
	appearance: textfield;
}

.containerShadow {
	box-shadow: rgba(0, 0, 0, 0.1) 0 0 5px 0, rgba(0, 0, 0, 0.1) 0 0 1px 0;
}

// Custom styles for active bootstrap tab link
.nav-tabs .nav-link.active {
	border-bottom: $tab-item-active-bottom-border;
	background-color: $tab-item-active-background;
	font-weight: bold;
}

.nav-tabs .nav-item {
	margin-bottom: 0;
}

// Custom styles for bootstrap tab pane
.tab-pane {
	overflow-y: hidden;
}

// Styles for custom tooltip component
.whiteTooltip {
	opacity: 1 !important;
}

.whiteTooltip .tooltip-inner {
	position: absolute;
	right: -2rem;
	bottom: 8px;
	min-width: 340px;
	height: auto;
	padding: 1rem;
	background-color: #fff;
	color: #323232;
	box-shadow: rgba(0, 0, 0, 0.05) 0 6px 24px 0, rgba(0, 0, 0, 0.08) 0 0 0 1px;
	font-size: 16px;
	text-align: left;
	animation: fade-in 0.3s ease;
}

.whiteTooltip .tooltip-arrow {
	position: absolute;
	bottom: 0;
	z-index: 120;
	width: 0;
	height: 0;
	border-top: 8px solid #fff;
	border-right: 8px solid transparent;
	border-left: 8px solid transparent;
}

@keyframes fade-in {
	from { opacity: 0.2; }
	to { opacity: 1; }
}
