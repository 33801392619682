@import '../../../utils/sass/mixins.scss';

.heroSection {
	padding: 2rem;

	.contentContainer {
		@include flex(center, flex-start, column);

		width: 80%;
		height: 70%;
		margin: 0 auto;

		.heroSectionHeading {
			font-size: 40px !important;
		}

		.heroSectionDescription {
			color: #4a4a4a;
			font-size: 20px;
		}
	}

	.gridColumn {
		.imagesContainer {
			display: grid;
			grid-template-rows: repeat(4, 1fr);
			grid-template-columns: repeat(4, 1fr);
			grid-gap: 5px;
		}
	}

	.countdownTimer {
		font-size: 45px;
	}
}

@media (min-width: 1440px) {
	.heroSection .contentContainer .heroSectionHeading {
		font-size: 56px !important;
	}
}

@media (max-width: 786px) {
	.heroSection {
		padding: 1rem;

		.contentContainer {
			.heroSectionDescription {
				font-size: 18px;
			}

			.heroSectionHeading {
				font-size: 36px !important;
			}
		}
	}
}

@media (max-width: 992px) {
	.heroSection {
		.contentContainer {
			width: 100%;
			height: 100%;
			padding: 2rem 0 4rem;
		}

		.gridColumn {
			padding-bottom: 4rem;
		}
	}
}
