.title {
	padding-bottom: 30px;
	font-weight: bold;
	font-size: 38px;
}

.term {
	padding-bottom: 30px;
	color: #4a4a4a;
	font-size: 18px;

	p {
		margin: 0;
		padding: 0;
		line-height: 2rem;
	}

	.heading {
		padding-top: 5px;
		padding-bottom: 4px;
		color: #333232;
		font-weight: bold;
		font-size: 30px;
	}

	a {
		color: #428eff;
	}
}

@media (max-width: 768px) {
	.title {
		font-size: 30px;
	}

	.term {
		font-size: 16px;

		.heading {
			font-size: 24px;
		}
	}
}
