@import '../../../../utils/sass/mixins.scss';
@import '../../../../utils/sass/variables.scss';

.propertyCard {
	position: relative;
	width: 100%;
	padding-bottom: 1rem;

	.descriptionText {
		margin: 0;
		color: #4a4a4a;
	}

	.cardTitleContainer {
		@include flex(space-between);

		padding-top: 10px;
	}

	&:hover {
		box-shadow: $primary-hover !important;
	}
}

.linkImage {
	overflow: hidden;
	width: 100%;
	border-radius: 4px 4px 0 0;

	img {
		transition: all 0.2s ease-in;
	}

	&:hover img {
		transform: scale(1.05);
	}
}

.icons {
	@include flex();

	position: relative;
	gap: 0.7rem;

	.icon {
		path {
			transition: fill 0.2s ease-out;
		}

		&:hover {
			cursor: pointer;

			path {
				fill: $darker-red;
			}
		}
	}
}

.postedTime {
	position: absolute;
	top: 14px;
	left: 14px;
	z-index: 2;
	width: fit-content;
	padding: 3px 20px;
	border-radius: 30px;
	background-color: rgba(0, 0, 0, 0.52);
	color: #fff;
	font-size: 12px;
}
