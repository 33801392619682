.message {
	width: 75%;
	margin: 0 auto;
}

@media (max-width: 768px) {
	.message {
		width: 100%;
	}
}
