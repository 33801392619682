@import '../../../../utils/sass/variables.scss';

.sidebar {
	min-width: 360px;
	min-height: 100vh;
	background-color: #fafafa;
	transition: all 0.3s ease;

	.bio {
		padding: 4rem 2rem 1rem;
		border-bottom: 1px solid #c1c1c1;
	}

	.links {
		margin: 2rem 0 4rem;

		svg {
			width: 24px;
			margin-top: -6px;
			margin-right: 6px;
		}

		.link {
			margin-bottom: 0;
			padding: 1rem 2rem;
			color: #fff;
			font-weight: 400;
			cursor: pointer;
			transition: background 0.3s ease;

			&:hover,
			&.active {
				background-color: $darker-red;

				svg,
				path {
					fill: #fff;
					stroke: #fff;
				}
			}

			&:not(.active) {
				color: #747474;

				&:hover {
					color: #fff;
				}
			}
		}
	}
}

@media (max-width: 768px) {
	.sidebar {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		z-index: 40;
		transform: translateX(-100%);

		&.show {
			transform: translateX(0%);
		}
	}
}
