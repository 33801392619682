@import '../../../../../utils/sass/mixins.scss';

.toggleButtons {
	@include flex(flex-end);

	flex-wrap: wrap;
	gap: 10px;

	.toggleButton {
		@include flex();

		gap: 10px;
		width: fit-content;
		padding: 7px 20px;
		border: $toggle-button-unselected-border;
		border-radius: 5px;
		background-color: $toggle-button-unselected-background;
		color: $toggle-button-unselected-text;
		outline: none !important;
		transition: background-color 0.3s;

		& path {
			fill: #000;
		}

		&.selected {
			background-color: $toggle-button-selected-background;
			color: $toggle-button-selected-text;

			& path {
				fill: #fff;
			}
		}

		&:hover:not(.selected) {
			background-color: $toggle-button-hover-unselected-background;
			color: $toggle-button-hover-unselected-text;

			& path {
				fill: #fff;
			}
		}
	}
}

.graphsContainer {
	@include flex();
}
