@import '../../../utils/sass/mixins.scss';

.tableHeader {
	background-color: #f5f5f5;

	th {
		padding: 1rem;
		border-bottom: none !important;
		white-space: nowrap;
	}
}

.tableBody td {
	padding: 1.6rem 1rem;
	color: #4a4a4a;
	white-space: nowrap;
}

.tagText {
	font-size: 14px !important;
}

.tableContainer {
	overflow: hidden;
	border-radius: 6px;
}

.downloadLink {
	@include underlinedLink();

	color: #1f1d1d !important;
}
