@import '../../utils/sass/mixins.scss';

.alert {
	@include flex(space-between);

	margin-top: -80px;
	margin-bottom: 2rem;
	padding: 1rem;
	border-radius: 6px;
	background-color: #ffe2e2;

	.textContent {
		@include flex();

		gap: 1rem;

		.title,
		.paragraph {
			margin: 0;
		}
	}

	.cross {
		transition: opacity 0.3s ease;

		&:hover {
			opacity: 0.5;
			cursor: pointer;
		}
	}
}
