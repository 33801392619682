@import '../../../../utils/sass/mixins.scss';

.titleBar {
	@include flex(space-between, center, row);
}

@media (max-width: 992px) {
	.titleBar {
		@include flex(center, flex-start, column);

		gap: 10px;
	}

	.sectionTitle {
		font-size: 26px !important;
		white-space: nowrap;
	}
}

@media (min-width: 1200px) {
	.sectionTitle {
		margin-right: -10rem;
	}
}
