@import '../../../utils/sass/mixins.scss';

.imagesGrid {
	display: grid;
	grid-template-areas:
		'mainImage mainImage mainImage secondImage secondImage secondImage'
		'mainImage mainImage mainImage secondImage secondImage secondImage'
		'thirdImage thirdImage fourthImage fourthImage lastImage lastImage'
		'thirdImage thirdImage fourthImage fourthImage lastImage lastImage';
	gap: 8px;
	width: 100%;

	.imageContainer {
		position: relative;
		overflow: hidden;
		border-radius: 4px;

		&::after {
			content: '';
			background-color: rgba(0, 0, 0, 0.5);
			opacity: 0;
			transition: opacity 0.3s ease-out;
		}

		.maximizeIcon {
			position: absolute;
			display: grid;
			opacity: 0;
			transition: opacity 0.3s ease-out;
			inset: 0;
			place-items: center;

			svg {
				stroke: #fff;
			}
		}

		&:not(:last-child):hover {
			.maximizeIcon {
				z-index: 99;
				opacity: 1;
				cursor: pointer;
			}

			&::after {
				position: absolute;
				opacity: 1;
				inset: 0;
			}
		}
	}

	.seeAllPhotos {
		@include primaryButton();

		position: absolute;
		right: 8px;
		bottom: 8px;
		padding: 6px 14px;
		background-color: #fff;
		color: #000;
		font-size: 14px;
		cursor: pointer;

		&:hover {
			background: #000;
			color: #fff;
		}
	}

	.mainImage {
		position: relative;
		grid-area: mainImage;

		.seeAllPhotos {
			display: none;
		}
	}

	.secondImage {
		grid-area: secondImage;
	}

	.thirdImage {
		grid-area: thirdImage;
	}

	.fourthImage {
		grid-area: fourthImage;
	}

	.lastImage {
		position: relative;
		grid-area: lastImage;
	}
}

@include responsive(smallTablet) {
	.imagesGrid {
		display: initial !important;

		.seeAllPhotos {
			display: initial !important;
		}

		.secondImage,
		.thirdImage,
		.fourthImage,
		.lastImage {
			display: none;
		}
	}
}
