@import '../../../../utils/sass/mixins.scss';

.container {
	margin: 24px 0;
	padding: 1rem;
	border-radius: 10px;
}

.rowContainer {
	@include flex(space-between);
}

.icon {
	cursor: pointer;
}

.tagText {
	color: inherit;
	font-size: 14px !important;
}

.downloadLink {
	@include underlinedLink();

	color: rgb(31, 29, 29);

	&:hover {
		color: rgb(31, 29, 29);
	}
}
