@import '../../utils/sass/mixins.scss';
@import '../../utils/sass/variables.scss';

.searchForm {
	width: 100%;
}

.backdrop {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 80;
	overflow: hidden;
	width: 100%;
	height: 120vh;
	background-color: #000;
	opacity: 0.8;
}

.backdropOpen {
	display: block;
}

.searchBar {
	position: relative;
	z-index: 90;
	padding: 0;
}

.searchInput {
	width: 100%;
	margin: 0;
	padding: 7px 5px;
	border: none;
	border-radius: 5px 0 0 5px;
	outline: none;

	&::placeholder {
		color: #979797;
	}

	&.focused {
		border-radius: 5px 0 0;
	}
}

.inputWrapper {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 0 10px;
	border: 1px solid #979797;
	border-radius: 5px 0 0 5px;
	background-color: #fff;

	.clearButton {
		@include flex();

		width: 24px;
		height: 24px;
		margin: 0;
		border-radius: 100%;
		cursor: pointer;
		transition: background-color 0.2s ease;

		&:hover {
			background-color: #dfdfdf;
		}
	}

	.mobileSearchButton {
		@include flex();

		width: 24px;
		height: 24px;
		margin: 0;
		border-radius: 100%;
		color: black;
		cursor: pointer;
		transition: background-color 0.2s ease;

		&:hover {
			background-color: #dfdfdf;
		}
	}

	&.mobileInputWrapper {
		margin-bottom: 5px;
		padding: 8px 10px;
		border: none;
		border-radius: 0;
	}
}

.searchButton {
	@include primaryButton();

	padding: 6.5px 10px;
	border-radius: 0 5px 5px 0;
	outline: none !important;

	&.focused {
		border-radius: 0 5px 0 0;
	}
}

.searchResultsContainer {
	position: absolute;
	top: 39px;
	left: 0;
	z-index: 100;
	width: 100%;
	padding-bottom: 20px;
	border: 1px solid #d5d5d5;
	background-color: #fff;

	&.mobileSearchResultsContainer {
		top: 53px;
	}
}

.currentLocation {
	padding: 14px;
}

.searchResult {
	padding: 6px 14px;
}

.currentLocation,
.searchResult {
	transition: background-color 0.2s ease;

	&:hover {
		background-color: #dfdfdf;
		cursor: pointer;
	}
}

.recentSearchTitle,
.placesTitle,
.mutedText {
	color: #979797;
	font-size: 12px;
}

@media (max-width: 390px) {
	.searchBar {
		justify-content: center;
	}
}
