@import '../../../utils/sass/variables.scss';
@import '../../../utils/sass/mixins.scss';

.sidebarContainer {
	position: fixed;
	top: 0;
	right: -100%;
	z-index: 100;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	overflow-y: scroll;
	width: 100%;
	height: 100vh;
	background-color: #fff;
	transition: right 0.3s ease-in-out;

	.closeButton {
		cursor: pointer;
	}
}

.sidebarOpen {
	right: 0;
}

.sidebarTopContainer {
	overflow-y: scroll;
	height: 100%;
	padding: 10px 0;
}

.signUpButton {
	@include primaryButton();

	width: 48%;
	margin-right: 2%;
	border: 1px solid $darker-purple;
	background-color: $darker-purple;

	&:hover {
		background-color: $primary-purple;
	}
}

.loginButton {
	@include secondaryButton();

	width: 48%;
	margin-left: 2%;
}

.navLink {
	margin: 6px 0;
	padding: 7px 10px;
	border: none !important;
	border-radius: 5px;
	background-color: #fff;
	color: #000 !important;
	transition: all 0.3s ease-out;

	&:hover {
		background-color: #b2d0fc;
	}
}

.addPropertyLink {
	@include secondaryButton;

	margin: 0 0 10px 6px;
	padding: 7px 20px !important;
	color: #000 !important;

	span {
		margin: 0 2px;
	}

	&:hover {
		border: 1px solid #4283ff !important;
		background-color: #428eff;
	}
}

.divider {
	width: 100%;
	background-color: #d9d9d9;
}

.menuContainer {
	@include flex(flex-end, flex-end, column);

	width: 80%;
	margin: 0 auto;

	.logOutButton {
		margin: 6px 0;
		padding: 7px 10px;
		border: none !important;
		border-radius: 5px;
		background-color: #fff;
		color: #000 !important;
		transition: all 0.3s ease-out;

		&:hover {
			background-color: #b2d0fc;
		}
	}
}

.learnAboutContainer {
	margin: 12px 0;
	padding: 5px 20px;
	background-color: #f5f5f5;

	.learnAboutText {
		width: 80%;
		margin: 0 auto;
		color: #979797 !important;
		font-size: 12px !important;
		text-align: right;
		text-transform: uppercase;
	}
}

.sidebarBottomSection {
	width: 100%;
	padding: 10px 0;
	background-color: #fff;
}

.citiesContainer {
	width: 80%;
	margin: 0 auto;
	padding: 0;
	background-color: #fff;

	.city {
		@include link();

		margin-left: 15px;
		padding: 10px;
		border: none !important;
		border-radius: 5px;
		color: #000;
		transition: all 0.3s ease-out;

		&:hover {
			background-color: #b2d0fc;
		}
	}
}

.profileButton {
	@include flex();

	padding: 10px;
	border: none;
	border-radius: 5px;
	background-color: white;

	.avatar {
		@include flex();

		width: 40px;
		height: 40px;
		border-radius: 50%;
		background-color: $darker-purple;
		color: #fff;
		font-weight: bold;
		font-size: 18px;
	}

	.profileName {
		margin-left: 10px;
		color: #000;
		font-weight: bold;
		font-size: 16px;
	}

	&:hover {
		background-color: #b2d0fc;
	}
}
