@import '../../../../utils/sass/mixins.scss';
@import '../../../../utils/sass/variables.scss';

$paragraphTextColor: #4a4a4a;

.claimPropertySection {
	padding: 20px 18px;
	border: 1px solid #000;
	border-radius: 4px;

	p {
		color: $paragraphTextColor;
	}

	.callToActions {
		@include flex(center, center, column);

		.buyMyShareButton,
		.wholePropertyButton {
			width: 280px !important;
		}

		.buyMyShareButton {
			@include primaryButton();

			margin-top: 30px;
		}

		.link {
			@include link();

			margin: 10px 0;
			color: $paragraphTextColor;
		}

		.wholePropertyButton {
			@include secondaryButton();

			margin-top: 6px;
		}
	}

	.progressBarContainer {
		height: 12px;
		border-radius: 2px;

		.progressBar {
			width: 50%;
			background: #b69d44;
		}
	}
}

@media (max-width: 768px) {
	.claimPropertySection .callToActions {
		.buyMyShareButton,
		.wholePropertyButton {
			width: 90% !important;
			padding: 7px 0;
		}
	}
}
