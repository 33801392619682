@import '../../utils/sass/mixins.scss';

.noteText {
	margin: 10px 0 32px;
	color: #4a4a4a;
	font-size: 18px;
}

.progressBar {
	width: 100%;
	height: 14px;
	border-radius: 4px;
	background-color: #d9d9d9;
}

@include responsive(smallScreen) {
	.noteText {
		font-size: 16px;
	}
}
