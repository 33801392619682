@import '../../../utils/sass/mixins.scss';

.propertyLink {
	text-decoration: none !important;
}

.propertyCard {
	position: relative;
	width: 100%;
	transition: all 0.3s ease-out;

	&:hover {
		box-shadow: $primary-hover !important;
	}
}

.linkImage {
	overflow: hidden;
	width: 100%;
	height: 250px;
	border-radius: 4px 4px 0 0;

	img {
		object-fit: cover;
		height: 100%;
		transition: all 0.2s ease-in;
		object-position: center;
	}
}

.cardHeading {
	color: #000;
}

.icons {
	position: relative;

	.favoriteIcon,
	.shareIcon {
		width: 24px;
		margin-left: 10px;

		path {
			transition: fill 0.2s ease-out;
		}

		&:hover {
			cursor: pointer;

			path {
				fill: #cd0101;
			}
		}
	}

	.favoriteIcon:hover path {
		stroke: #cd0101;
	}

	.favoriteIcon.filled path {
		fill: #cd0101;
		stroke: #cd0101;
	}
}

.postedTime {
	position: absolute;
	top: 14px;
	left: 14px;
	z-index: 2;
	width: fit-content;
	padding: 3px 20px;
	border-radius: 30px;
	background-color: rgba(0, 0, 0, 0.52);
	color: #fff;
	font-size: 12px;
}

.cardText {
	height: 96px;
	color: #000;
	font-size: 14px;
	text-align: left;
}

.reportButton {
	@include secondaryButton();

	width: 48%;
	padding: 7px 0;
}

.editButton {
	@include primaryButton();

	width: 48%;
	padding: 7px 0;
}
