@import '../../../../../utils/sass/mixins.scss';
@import '../../../../../utils/sass/variables.scss';

.modalWrapper {
	width: 100%;

	.modalDialog {
		width: 90%;
		max-width: 700px;
		margin: 0 auto;
		border-radius: 20px;
	}
}

.modalHeading {
	color: #000;
	font-weight: 800;
	text-align: center;
}

.formContainer {
	width: 80%;
	margin: 0 auto;

	.passwordField {
		position: relative;
		cursor: pointer;
	}
}

.modalButtonsContainer {
	@include flex();

	width: 100%;

	.cancelButton {
		@include secondaryButton();

		width: 200px;
	}

	.submitButton {
		@include primaryButton();

		width: 200px;
		border: 1px solid $darker-red !important;
	}
}

.validationError {
	color: #f00;
}

@media (max-width: 768px) {
	.modalButtonsContainer {
		@include flex(center, center, column);
	}

	.formContainer {
		width: 90%;
	}
}

@media (max-width: 640px) {
	.formContainer {
		width: 95%;
	}
}
