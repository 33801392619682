@import '../../../../utils/sass/mixins.scss';

.detailTabs {
	overflow-x: scroll;
	overflow-y: hidden;

	li {
		white-space: nowrap;
	}

	.detailTab {
		@include tabItem();
	}
}
