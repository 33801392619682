@import '../../utils/sass/mixins.scss';
@import '../../utils/sass/variables.scss';

.inputLabel {
	@include flex(space-between, flex-start);

	padding-bottom: 0.1rem;
	color: #4a4a4a;

	.labelContainer {
		@include flex(flex-start);

		flex-wrap: wrap;
		gap: 6px 10px;
	}

	.statusTag {
		display: flex;
		gap: 6px;
		align-items: center;
		padding: 0.2rem 0.6rem;
		border-radius: 4px;
		background-color: #ddd;
		color: #4a4a4a;
		font-size: 12px;
	}

	.dangerText {
		color: $darker-red;
	}
}

.cnicUploadContainer {
	position: relative;
	display: inline-block;
	width: 100%;

	.cnicUploadLabel {
		width: 100%;
		margin-bottom: 0;
		padding: 1rem;
		border: 1px solid #979797;
		border-radius: 5px;
		background: #fff;
		color: #4a4a4a;
		text-align: center;
		transition: background 0.3s;

		&:hover {
			background: #e6e6e6;
			cursor: pointer;
		}
	}

	.cnicUploadInput {
		position: absolute;
		width: 0;
		height: 100%;
		opacity: 0;
		inset: 0;
	}

	.cnicImagePreview {
		position: relative;
		width: 220px;
		height: 140px;
		margin-top: 0.8rem;

		.bin {
			position: absolute;
			top: 6px;
			right: 6px;
			cursor: pointer;

			rect,
			path {
				transition: all 0.3s ease;
			}

			&:hover rect {
				fill: #000;
			}

			&:hover path {
				fill: #fff;
			}
		}

		.cnicImage {
			width: 100%;
			height: 100%;
			border-radius: 6px;
		}
	}
}
