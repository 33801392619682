.continueReadingButton {
	display: block;
	margin-top: 0.4rem;
	font-weight: 600;
	cursor: pointer;
	user-select: none;

	.continueReadingText {
		margin-right: 0.2rem;
		text-decoration: underline;
	}

	svg {
		width: 14px;
	}
}
