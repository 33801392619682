@import '../../utils/sass/variables.scss';
@import '../../utils/sass/mixins.scss';

.errorHeading {
	color: $darker-red;
	font-size: 160px !important;
}

.notExistHeading {
	color: #000;
}

.sorryText {
	color: #4a4a4a;
}

.homePageLink {
	@include underlinedLink();
}

@media (max-width: 768px) {
	.errorHeading {
		font-size: 100px !important;
	}
}

@media (max-width: 480px) {
	.errorHeading {
		font-size: 80px !important;
	}
}
