@import '../../../utils/sass/mixins.scss';

.cards {
	flex-wrap: nowrap;
	align-items: center;
	justify-content: space-between;
	overflow-x: scroll;
	padding: 1rem 0 !important;
	white-space: nowrap;

	.cardColumn {
		min-width: 360px;
		margin: 0;
		padding-right: 6px;

		&:first-child {
			padding-left: 4px;
		}

		.card {
			overflow: hidden;
			margin: 0;
			border: none;
			border-radius: 6px;
			background-color: #fff;
		}
	}

	.cardBottom {
		@include flex(space-between);

		gap: 1rem;
		background-color: #fff;

		p {
			margin: 0;
		}
	}
}

.trendText {
	margin-bottom: 0;
	margin-left: 16px;
	color: #269e57;
	font-weight: bold;
}

@include responsive(extraSmallDevice) {
	.cards .cardColumn {
		min-width: 340px;
		padding-right: 2px;

		.cardBottom {
			gap: 0;
		}
	}
}
