@import '../../utils/sass/variables.scss';

.accordionItem {
	overflow: hidden;
	width: 100%;
	margin: 10px 0;
	padding: 4px 14px;
	border-radius: 5px;
	background-color: #fff;
	box-shadow: 2px 2px 16px 0 rgba(0, 0, 0, 0.1);
	transition: all 0.3s ease;

	&:hover {
		box-shadow: $primary-hover;
	}

	&.accordionItemOpen {
		padding-bottom: 0;
	}

	.accordionButton {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		padding: 10px;
		border: none;
		background-color: transparent;
		color: #000;
		font-size: 17px;
		transition: all 0.3s ease;

		.accordionIcon {
			transition: all 0.3s ease;

			&.currentAccordionIcon {
				transform: rotate(180deg);
			}
		}

		.accordionLabel {
			margin: 0;
			font-size: 20px !important;
		}

		&:hover,
		&:focus {
			border: none;
			outline: none;
		}
	}

	.accordionBodyText {
		margin: 0;
		padding: 0 14px 14px;
		font-size: 18px !important;
	}

	.accordionLink {
		padding: 0 14px;
	}
}
