@import '../../utils/sass/mixins.scss';
@import '../../utils/sass/variables.scss';

.pageHeading {
	margin: 24px 0;
	font-weight: bold;
	text-align: center;
}

.knowledgeHubPageLink {
	text-decoration: none !important;
}

.knowledgeHubCard {
	overflow: hidden;
	width: 100%;
	margin: 14px auto;
	border-radius: 5px;
	transition: all 0.3s ease-in;

	&:hover {
		box-shadow: $primary-hover !important;
	}

	.cardImageContainer {
		width: 100%;
		height: 250px;

		.cardImage {
			object-fit: cover;
			width: 100%;
			height: 100%;
			object-position: center;
		}
	}

	.cardContent {
		@include flex();

		min-height: 96px;
		text-align: center;

		.cardHeading {
			margin: 0;
			padding: 0 20px;
			color: #000;
		}
	}
}
