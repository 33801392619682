@import '../../../utils/sass/mixins.scss';

.steps {
	@include flex(flex-start);

	flex-wrap: wrap;
	margin: 2.6rem 0;
	padding: 0.6rem 1rem;
	border: 1px solid #7e7e7e;
	border-radius: 4px;
	background-color: #f5f5f5;

	.step {
		@include flex();

		gap: 4px;

		&:not(:first-child) {
			color: #7e7e7e;

			.unfilledTickIcon {
				opacity: 0.4;
			}
		}

		.rightArrow {
			padding: 0 0.8rem;
		}

		&.current {
			color: #000;
			font-weight: bold;

			.unfilledTickIcon {
				opacity: 1;
			}
		}
	}
}
