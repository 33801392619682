@import './variables.scss';

@mixin flex($justify: center, $align: center, $direction: row) {
	display: flex;
	flex-direction: $direction;
	align-items: $align;
	justify-content: $justify;
}

@mixin link() {
	border-bottom: 1px solid transparent;
	text-decoration: none;
	transition: border 0.2s ease-in;

	&:hover {
		border-color: #000;
	}
}

@mixin underlinedLink() {
	border-bottom: 1px solid $primary-blue;
	color: $primary-blue;
	text-align: center;
	text-decoration: none !important;
	transition: all 0.2s ease-out;

	&:hover {
		border-color: transparent;
		color: $darker-blue;
	}
}

@mixin hideScrollBar() {
	scrollbar-width: none;
	-ms-overflow-style: none;

	&::-webkit-scrollbar {
		display: none;
	}
}

@mixin primaryButton() {
	@include responsive(smallDevice) {
		padding: 7px 20px;
	}

	width: fit-content;
	padding: 7px 70px;
	border: 1px solid transparent;
	border-radius: 5px;
	background-color: $darker-red;
	color: #fff;
	outline: none !important;
	text-align: center;
	cursor: pointer;
	transition: background-color 0.3s;

	&:hover {
		background-color: $primary-red;
	}

	&:disabled {
		background-color: rgba(103, 0, 0, 0.801);
		color: rgba(255, 255, 255, 0.59);
		cursor: default;
	}
}

@mixin secondaryButton() {
	@include responsive(smallDevice) {
		padding: 7px 20px;
	}

	width: fit-content;
	padding: 7px 70px;
	border: 1px solid #000;
	border-radius: 5px;
	background-color: #fff;
	color: #000 !important;
	outline: none !important;
	text-align: center;
	cursor: pointer;
	transition: background-color 0.3s;

	&:hover {
		border: 1px solid $primary-blue;
		background-color: $primary-blue;
	}

	&:disabled {
		background-color: rgba(236, 236, 236, 0.529);
		color: rgba(0, 0, 0, 0.59);
		cursor: default;
	}
}

@mixin tabItem() {
	padding: 4px 20px 10px;
	border: none;
	border-bottom: $tab-item-default-bottom-border;
	background: $tab-item-default-background;
	color: #4a4a4a;
	outline: none;
	transition: color 0.2s ease;

	&:hover {
		color: #000;
	}
}

/* Breakpoint mixin for all devices */
@mixin responsive($breakpoint) {
	@if $breakpoint == smallDevice {
		@media (max-width: 576px) {
			@content;
		}
	}

	@else if $breakpoint == extraSmallDevice {
		@media (max-width: 480px) {
			@content;
		}
	}

	@else if $breakpoint == smallTablet {
		@media (max-width: 768px) {
			@content;
		}
	}

	@else if $breakpoint == largeTablet {
		@media (max-width: 992px) {
			@content;
		}
	}

	@else if $breakpoint == laptop {
		@media (max-width: 1200px) {
			@content;
		}
	}

	@else if $breakpoint == desktop {
		@media (max-width: 1440px) {
			@content;
		}
	}

	@else if $breakpoint == bigScreen {
		@media (min-width: 1441px) {
			@content;
		}
	}

	@else {
		@content;
	}
}
