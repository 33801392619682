@import '../../utils/sass/mixins.scss';

.container {
	padding: 0 100px;
}

@include responsive(bigScreen) {
	.container {
		padding: 0 200px;
	}
}

@media (min-width: 1700px) {
	.container {
		padding: 0 240px;
	}
}

@include responsive(largeTablet) {
	.container {
		padding: 0 16px;
	}
}
