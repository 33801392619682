@import '../../../utils/sass/mixins.scss';

.submitButtons {
	@include flex();

	gap: 10px;
	width: 94%;
	margin: 1rem auto 0;
}

@include responsive(smallDevice) {
	.submitButtons {
		width: 100%;
	}
}
