@import '../../utils/sass/mixins.scss';
@import '../../utils/sass/variables.scss';

.navbarWrapper {
	z-index: 90;
	flex-direction: column;
	width: 100%;
	padding: 0;
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
}

.navbarContainer {
	@include flex(space-between, center, row);

	width: 100%;
}

.logo {
	cursor: pointer;
}

.menuContainer {
	@include flex(flex-end, center, row);
}

.profileMenuContainer,
.authMenuContainer {
	@include flex();

	margin-left: 20px;
	padding-left: 20px;
	border-left: 1px solid #d9d9d9;

	.loginLink {
		@include link();

		margin-right: 10px;
		padding: 10px 16px !important;
		border: none !important;
		border-radius: 5px;
		color: #000 !important;
		font-size: 16px;
		transition: all 0.3s ease-out;

		&:hover {
			background-color: #b2d0fc;
		}
	}

	.signUpLink {
		@include primaryButton();

		padding: 10px 16px !important;
		background-color: $darker-purple;
		color: #fff !important;
		font-size: 16px;

		&:hover {
			background-color: $primary-purple;
		}
	}
}

.profileButton {
	@include flex();

	padding: 7px 10px;
	border: none;
	border-radius: 5px;
	background-color: #fff;
	box-shadow: none !important;

	.avatar {
		@include flex();

		width: 30px;
		height: 30px;
		border-radius: 50%;
		background-color: $darker-purple;
		color: #fff;
		font-weight: bold;
		font-size: 18px;
	}

	.profileName {
		margin-right: 10px;
		color: #000;
	}

	&::after {
		display: none;
	}

	&.profileButtonOpen,
	&:hover,
	&:focus {
		border: none;
		background-color: #b2d0fc !important;
	}
}

.addPropertyLink {
	@include secondaryButton();

	margin: 0 20px 0 6px;

	span {
		margin: 0 2px;
	}
}

.profileDropdown {
	background-color: transparent;

	.dropDownMenu {
		right: 0;
		left: auto;
		margin-top: 16px;
		padding-bottom: 0;
		border: 1px solid #eeeded;
		border: none;
		border-radius: 6px;
		box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;

		&::after {
			content: '';
			position: absolute;
			top: -9px;
			right: 19px;
			display: block;
			width: 15px;
			height: 15px;
			border-width: 1px 0 0 1px;
			border-style: solid;
			border-color: #ccc;
			background: inherit;
			transform: rotate(45deg);
		}

		.dropDownItem {
			@include link();

			padding: 10px 20px;
			border: none !important;
			color: #000 !important;
			transition: all 0.3s ease-out;

			&:hover {
				background-color: #b2d0fc;
			}

			&:last-child {
				border-radius: 0 0 5px 5px;
			}
		}

		.learnAboutContainer {
			margin: 12px 0;
			padding: 5px 20px;
			background-color: #f5f5f5;

			.learnAboutText {
				margin: 0;
				color: #979797 !important;
				font-size: 12px !important;
				text-transform: uppercase;
			}
		}

		.divider {
			width: 100%;
			margin: 10px 0 0;
			background-color: #d9d9d9;
		}
	}
}

.cities {
	width: 100%;
	padding: 10px 0;
	border-top: 1px solid #d9d9d9;
	background-color: #fff;

	.city {
		@include link();

		margin-right: 36px;
		padding: 13px 10px;
		border: none !important;
		color: #000 !important;
		transition: all 0.3s ease-out;

		&:hover {
			background-color: #b2d0fc;
		}
	}
}

.navLink {
	@include link();

	margin: 0 6px;
	border: none !important;
	border-radius: 5px;
	color: #000 !important;
	transition: all 0.3s ease-out;

	&:hover {
		background-color: #b2d0fc;
	}
}

.navLink:last-child {
	margin: 0 0 0 6px;
}

.mobileSearchBar {
	position: absolute;
	top: -100px;
	left: 0;
	z-index: 100;
	width: 100%;
	background-color: #fff;
	transition: 0.3s ease-in-out;

	&.openSearchBar {
		top: 0;
	}
}

.searchButton {
	@include flex();

	margin-right: 10px;
	cursor: pointer;

	.searchIcon {
		width: 36px;
		height: 36px;
	}
}

.menuButton {
	@include flex();

	cursor: pointer;

	.menuIcon {
		width: 36px;
		height: 36px;
	}
}

@media (max-width: 1400px) {
	.menuContainer {
		.navLink:nth-child(1),
		.navLink:nth-child(2),
		.navLink:nth-child(3) {
			display: none;
		}
	}
}

@media (max-width: 992px) {
	.cities {
		display: none;
	}
}
