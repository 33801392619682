@import '../../utils/sass/variables.scss';
@import '../../utils/sass/mixins.scss';

.filterSortButtons {
	@include flex(flex-start, center);

	flex-wrap: wrap;
	gap: 10px;

	.sortContainer,
	.filterContainer {
		@include flex(flex-start, center);

		label {
			width: 40px;
		}
	}

	.filterSelecter {
		margin-left: 4px;
		border: none;
		background-color: transparent;
		outline: none;
		font-weight: bold;
	}
}

@include responsive(smallDevice) {
	.filterSortButtons {
		margin-top: 1rem;
	}
}
