@import '../../utils/sass/variables.scss';
@import '../../utils/sass/mixins.scss';

.sliceLottieSection {
	position: relative;
	overflow: hidden;
	background: linear-gradient(45deg, #cbd6f2, #a3c8ff);

	.cherryCakeLottieContainer {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 80%;
		margin: 20px auto;
		padding: 20px;
		border-radius: 10px;
		background-color: #fff;

		.cherryCakeLottie {
			width: 90%;
			margin: 10px;
		}
	}

	.lottieHeading {
		color: #224ab1;
		font-weight: bold;
		font-size: 64px !important;
	}

	.floatingHouses {
		position: absolute;
		right: 0;
		bottom: 0;
	}
}

.joinHissaySection {
	@include flex();

	width: 100%;
	padding: 80px 0;
	background-image: url('../../assets/landingScreen/join-hissay-bg-desktop.png');
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;

	.joinHissayHeading {
		color: #224ab1;
		font-weight: bold;
		font-size: 22px;
		text-align: center;
	}

	.joinHissayButton {
		@include primaryButton();

		margin: 20px 0 0;
		padding: 10px 100px;
		text-decoration: none;

		h5 {
			color: #fff;
			font-weight: bold;
			text-align: center;
		}
	}
}

@include responsive(laptop) {
	.joinHissaySection .joinHissayHeading {
		font-size: 22px !important;
	}
}

@include responsive(largeTablet) {
	.sliceLottieSection {
		.floatingHouses {
			display: none;
		}

		.lottieHeading {
			margin-top: 20px;
			font-size: 40px !important;
			text-align: center;
		}
	}

	.joinHissaySection {
		height: 25vh;
		background-size: cover;

		.joinHissayButton {
			padding: 10px 80px;
		}
	}
}

@include responsive(smallDevice) {
	.joinHissaySection {
		height: 60vh;
		background-image: url('../../assets/landingScreen/join-hissay-bg-mobile.png');

		.joinHissayHeading {
			margin-top: 80px;
		}

		.joinHissayButton {
			padding: 10px 50px;
		}
	}
}
