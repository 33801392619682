@import '../../utils/sass/mixins.scss';

.joinHissaySection {
	@include flex();

	width: 100%;
	padding: 80px 0;
	background-image: url('../../assets/landingScreen/join-hissay-bg-desktop.png');
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;

	.joinHissayHeading {
		color: #224ab1;
		font-size: 22px;
		text-align: center;

		.strikethrough {
			position: relative;

			&::after {
				content: '';
				position: absolute;
				top: 50%;
				left: -0.2rem;
				width: 115%;
				height: 100%;
				background-image: url('../../assets/landingScreen/redStrikeThrough.svg');
				background-size: 94%;
				background-repeat: no-repeat;
			}

			&::before {
				content: '';
				position: absolute;
				top: -2.6rem;
				left: -3rem;
				width: 10rem;
				height: 3rem;
				background-image: url('../../assets/landingScreen/noLongerText.svg');
				background-position: center;
				background-repeat: no-repeat;
			}
		}
	}

	.joinHissayButton {
		@include primaryButton();

		margin: 50px 0 0;
		padding: 10px 100px;
		text-decoration: none;

		h5 {
			color: #fff;
			font-weight: bold;
			text-align: center;
		}
	}
}

@include responsive(largeTablet) {
	.joinHissaySection {
		background-size: cover;

		.joinHissayHeading {
			font-size: 22px !important;

			.strikethrough::before {
				background-size: 60%;
			}
		}

		.joinHissayButton {
			padding: 10px 80px;
		}
	}
}

@include responsive(smallDevice) {
	.joinHissaySection {
		background-image: url('../../assets/landingScreen/join-hissay-bg-mobile.png');
		background-position: top;
		background-size: cover;
		background-repeat: no-repeat;

		.joinHissayHeading {
			margin-top: 200px;
		}

		.joinHissayButton {
			padding: 10px 50px;
		}
	}
}

@include responsive(extraSmallDevice) {
	.joinHissaySection .joinHissayHeading {
		font-size: 20px !important;
	}
}
