@import '../../../utils/sass/variables.scss';
@import '../../../utils/sass/mixins.scss';

.filterSortButtons {
	@include flex(flex-start, center);

	flex-wrap: wrap;
	gap: 10px;

	.sortContainer,
	.filterContainer {
		@include flex(flex-start, center);

		label {
			width: 40px;
		}
	}
}

.gridViewContainer {
	width: 90%;
	padding: 28px 0;
}

.filterSelecter {
	margin-left: 4px;
	border: none;
	background-color: transparent;
	outline: none;
	font-weight: bold;
}

.viewButtons {
	@include flex();

	gap: 10px;

	.viewButton {
		@include flex();

		gap: 10px;
		width: fit-content;
		padding: 7px 20px;
		border: $toggle-button-unselected-border;
		border-radius: 5px;
		background-color: $toggle-button-unselected-background;
		color: $toggle-button-unselected-text;
		outline: none !important;
		transition: background-color 0.3s;

		&.selected {
			background-color: $toggle-button-selected-background;
			color: $toggle-button-selected-text;

			& path {
				fill: #fff;
			}
		}

		&:hover:not(.selected) {
			background-color: $toggle-button-hover-unselected-background;
			color: $toggle-button-hover-unselected-text;

			path {
				fill: $toggle-button-hover-unselected-text;
			}
		}
	}
}

.propertyRow {
	border-bottom: 1px solid grey;

	&:last-child {
		border-bottom: none;
	}
}

.loadMoreButton {
	@include secondaryButton();

	padding: 7px 40px;
}

.contactSellerButton,
.buyNowButton {
	@include primaryButton();

	width: 100%;
	padding: 7px 20px;
}

.contactSellerButton {
	margin-bottom: 10px;
}

@media (max-width: 992px) {
	.gridViewContainer {
		width: 100%;
	}
}

@media (max-width: 768px) {
	.screenTitle {
		font-size: 30px;
	}
}
