.sectionWrapper {
	width: 100%;
	background-color: #f6f6f6;
}

.sectionHeading {
	margin-bottom: 24px;
	color: #000;
	font-weight: 700;
	font-size: 30px;
}

.benefitsTag {
	display: flex;
	align-items: center;
	width: 100%;
	margin: 10px 10px 10px 0;
	padding: 10px;
	border-radius: 5px;
	color: #4a4a4a;
	box-shadow: 1px 2px 16px 0 rgba(0, 0, 0, 0.1);
	font-size: 20px;
	cursor: pointer;
	transition: all 0.3s ease;

	&:hover {
		box-shadow: 1px 2px 16px 0 rgba(66, 142, 255, 0.4);
	}

	&.currentBenefitsTag {
		background-color: #457eac;
		color: #fff;
	}

	.tagLabel {
		margin: 0;
		font-size: 20px;
	}

	.tagIconContainer {
		margin: 0 20px;
		padding: 10px;
		border-radius: 50%;
		background-color: #457eac;
		font-size: 10px;

		&.currentTagIconContainer {
			background-color: #fff;
		}
	}
}

.benefitContent {
	.benefitTitle {
		margin-bottom: 20px;
		font-size: 25px;
	}

	.benefitText {
		font-size: 18px;
		line-height: 30px;
	}
}

.benefitAccordionItem {
	overflow: hidden;
	width: 100%;
	margin: 10px 0;
	border-radius: 5px;
	background-color: #fff;
	box-shadow: 1px 2px 16px 0 rgba(0, 0, 0, 0.1);
	transition: all 0.3s ease;

	.accordionButton {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		padding: 10px;
		border: none;
		background-color: transparent;
		color: #000;
		font-size: 17px;
		transition: all 0.3s ease;

		&.currentAccordion {
			background-color: #457eac;
			color: #fff;
		}

		.accordionIcon {
			transition: all 0.3s ease;

			&.currentAccordionIcon {
				transform: rotate(180deg);
			}
		}

		&:hover,
		&:focus {
			border: none;
			outline: none;
		}
	}

	.accordionBody {
		margin: 10px;
		font-size: 16px;
	}
}

.tagIcon {
	width: 20px;
	height: 20px;
}
