@import '../../../utils/sass/mixins.scss';

.sectionHeading {
	margin-bottom: 24px;
	color: #000;
	font-weight: 700;
	font-size: 30px;
}

.sectionText {
	color: #4a4a4a;
	font-weight: 500;
	font-size: 18px;
	line-height: 30px;
}

.hissayWorkCard {
	width: 100%;
	margin: 15px 5px;
	padding: 24px;
	border-radius: 5px;
	box-shadow: 3px 4px 20px 0 rgba(0, 0, 0, 0.1);

	.cardImage {
		overflow: hidden;
		width: 100%;
		border-radius: 5px;
	}

	.cardContent {
		margin-top: 18px;
		text-align: center;

		.cardHeading {
			color: #000;
			font-weight: 600;
			font-size: 22px;
			font-family: Lato, sans-serif;
		}

		.cardText {
			min-height: 115px;
			color: #4a4a4a;
			font-weight: 500;
			font-size: 16px !important;
			line-height: 28px;
		}
	}

	.cardButton {
		@include secondaryButton();

		width: 100%;
		padding: 8px 0;
		border: 1px solid #4a4a4a;
	}
}

@include responsive(largeTablet) {
	.hissayWorkCard .cardContent .cardText {
		min-height: 115px;
	}
}
