@import '../../utils/sass/variables.scss';
@import '../../utils/sass/mixins.scss';

.mainContainer {
	border-top: 1px solid #979797;
	color: #979797;

	a {
		text-decoration: none;
	}

	p {
		color: #979797;
	}
}

.columnTitle {
	margin-bottom: 20px;
	color: #4a4a4a;
	font-weight: bold;
	white-space: nowrap;
}

.contactColumn {
	border-right: 1px solid #979797;
}

.links li {
	margin-bottom: 20px;
}

.link {
	@include link();

	color: #979797 !important;
}

.emailInput {
	display: block;
	width: 100%;
	padding: 10px;
	border: 1px solid #4a4a4a;
	border-radius: 5px;
	outline: none;
}

.subscribeButton {
	@include primaryButton();

	width: 100%;
}

@media (max-width: 992px) {
	.contactColumn {
		border-right: 0;
	}

	.links li {
		margin-bottom: 10px;
	}

	.columnTitle {
		margin-bottom: 10px;
	}
}

@media (max-width: 480px) {
	.copyrightRow {
		flex-wrap: wrap;

		.socialLinks {
			margin-top: 14px;
		}
	}
}
