@import '../../utils/sass/mixins.scss';

.progressBarOuter {
	display: flex;
	align-items: center;
	width: 100%;
	height: 10px;
	border-radius: 2px;

	.progressBarSegment {
		position: relative;
		z-index: 50;
		height: 100%;
		transition: height 0.2s ease-out;

		&:hover {
			height: 18px;
		}

		.tooltipContent {
			padding: 0.4rem 0.6rem;
			border-radius: 3px;
			background-color: #fff;

			p {
				font-size: 14px !important;
				white-space: nowrap;
			}
		}
	}
}

@include responsive(smallDevice) {
	.tooltipContent p {
		white-space: pre-wrap !important;
	}
}
