.floatingInputField {
	position: relative;
	margin-bottom: 20px;

	.visibilityIcon {
		position: absolute;
		top: 17px;
		right: 20px;
	}

	.percentageSymbol {
		position: absolute;
		top: 44%;
		right: 1rem;
		z-index: 99;
	}
}

.input {
	width: 100%;
	height: 65px;
	padding: 17px 20px 0;
	border: 1px solid #4a4a4a;
	border-radius: 6px;
	color: #4a4a4a;
	transition: all 0.2s;

	&:focus {
		outline: none;
	}

	&:disabled {
		background-color: #e7e7e7;
	}
}

.label {
	position: absolute;
	top: 0;
	left: 0;
	margin-top: 12px;
	padding: 7px 0 0 20px;
	color: #4a4a4a;
	transition: all 0.2s;
}

.inputFocused {
	.label {
		margin-top: 12px;
		font-size: 80%;
		transform: translate3d(0, -50%, 0);
	}
}

.input:focus + .label,
.input:not(:placeholder-shown) + .label {
	margin-top: 12px;
	font-size: 80%;
	transform: translate3d(0, -50%, 0);
}
