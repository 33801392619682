@import '../../utils/sass/mixins.scss';
@import '../../utils/sass/variables.scss';

.contactCtaSection {
	@include flex(center, center, column);

	position: relative;
	overflow: hidden;
	width: 100%;
	padding: 80px 0;
	background: $background-gradient;

	.contactHissayHeading {
		color: #224ab1;
		font-size: 22px;
		text-align: center;
	}

	.contactHissayButton {
		@include primaryButton();

		margin: 40px 0;
		padding: 10px 100px;
		text-decoration: none;

		h5 {
			font-weight: bold;
		}
	}
}

.ctaMobileCollageImage {
	width: 100%;
}

.ctaDesktopRightImage,
.ctaDesktopLeftImage {
	transition: all 0.3s linear;
}

@include responsive(laptop) {
	.ctaDesktopLeftImage {
		margin-left: -140px;
	}

	.ctaDesktopRightImage {
		margin-right: -140px;
	}
}

@include responsive(largeTablet) {
	.contactHissayButton {
		padding: 10px 80px;
	}

	.ctaDesktopLeftImage {
		margin-left: -240px;
	}

	.ctaDesktopRightImage {
		margin-right: -240px;
	}
}

@include responsive(smallTablet) {
	.contactCtaSection {
		padding: 40px 0;

		.contactHissayHeading {
			margin-top: 16px;
		}
	}

	.ctaDesktopLeftImage {
		margin-left: -320px;
	}

	.ctaDesktopRightImage {
		margin-right: -320px;
	}
}

@include responsive(smallDevice) {
	.contactHissaySection {
		padding: 40px 0;

		.contactHissayButton {
			padding: 10px 50px;
		}

		.contactHissayHeading {
			margin-top: 30px;
		}
	}
}
