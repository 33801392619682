@import '../../../../utils/sass/variables.scss';

.shareContainer {
	position: absolute;
	top: 34px;
	right: -34px;
	z-index: 100;
	width: 300px;
	padding: 14px 26px;
	background-color: #fff;
	box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.149);
	animation: fade-in 0.2s ease-in;

	&::after {
		content: ' ';
		position: absolute;
		right: 14%;
		bottom: 100%;  /* At the top of the tooltip */
		border-width: 8px;
		border-style: solid;
		border-color: transparent transparent #fff;
		filter: drop-shadow(0 0 0 rgb(0, 0, 0));
	}

	.copyField {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 14px;
		padding: 4px;
		border: 1px solid #dadada;
		border-radius: 4px;

		.copyText {
			width: 80%;
			padding: 0 10px;
			border: none;
			border-radius: 3px;
			color: #323232;
			outline: none;
			font-size: 14px;

			&.highlight {
				background-color: #d0eddf;
			}
		}

		.copyButton {
			margin: 0;
			padding-left: 10px;
			border: none;
			background-color: transparent;
			color: $darker-red;
			outline: none;
			font-size: 14px;

			&:disabled {
				pointer-events: none;
			}
		}
	}

	.socialIcons {
		display: flex;
		align-items: center;
		justify-content: space-between;

		img {
			cursor: pointer;
			transition: all 0.2s ease-out;

			&:hover {
				filter: drop-shadow(0.1rem 0.2rem 0.1rem rgba(0, 0, 0, 0.2));
			}
		}
	}
}

@keyframes fade-in {
	0% {
		opacity: 0;
		transform: translateY(-10px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}
