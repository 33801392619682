.floatingInputField {
	position: relative;
	margin-bottom: 20px;
}

.select {
	width: 100%;
	height: 65px;
	padding: 17px 20px 0;
	border: 1px solid #4a4a4a;
	border-radius: 6px;
	background: url('../../assets/arrowDown.svg') no-repeat 96% 66%;
	background-size: 12px 12px;
	color: #4a4a4a;
	appearance: none;

	&:focus {
		outline: none;
	}
}

.label {
	position: absolute;
	top: 0;
	left: 0;
	margin-top: 12px;
	padding: 7px 0 0 20px;
	color: #4a4a4a;
	transition: all 0.2s;
}

.select:focus + .label,
.select:valid + .label {
	margin-top: 15px;
	font-size: 80%;
	transform: translate3d(0, -50%, 0);
}
