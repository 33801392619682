@import '../../../utils/sass/variables.scss';
@import '../../../utils/sass/mixins.scss';

.container {
	position: relative;
	display: flex;

	.contentContainer {
		width: 100%;
		padding: 20vh 6rem;
	}
}

.sidebarToggleButton {
	display: none;
}

@include responsive(largeTablet) {
	.container .contentContainer {
		padding: 20vh 1rem;
	}
}

@include responsive(smallTablet) {
	.sidebarToggleButton {
		position: absolute;
		top: 10px;
		left: 10px;
		z-index: 50;
		display: block;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 28px;
		height: 28px;
		border-radius: 50%;
		background-color: $darker-red;
		cursor: pointer;

		.toggleIcon {
			display: inline-block;
			width: 14px;
			height: 2px;
			background-color: #fff;
			transition: transform 0.3s ease;

			&.closeIcon {
				transform: rotate(45deg);

				&::after {
					content: '';
					position: absolute;
					display: block;
					width: 14px;
					height: 2px;
					background-color: #fff;
					transform: rotate(-90deg);
				}
			}
		}
	}
}
