@import '../../../../utils/sass/mixins.scss';
@import '../../../../utils/sass/variables.scss';

$paragraphTextColor: #4a4a4a;

.actionBarContainer {
	position: sticky;
	top: 0;
	z-index: 10;
	padding: 2rem 0;
	background-color: white;

	.actionBar {
		@include flex(space-between, center, row);

		.actionLinks {
			@include flex();

			gap: 60px;
			white-space: nowrap;

			.tabs {
				.actionLink {
					@include tabItem();

					color: $paragraphTextColor;
					text-decoration: none;
					transition: font 0.2s ease-out, border 0.1s ease-out;

					&.active {
						border-bottom: $tab-item-active-bottom-border;
						background-color: $tab-item-active-background;
						font-weight: bold;
					}
				}
			}

			.searchLink {
				@include flex();

				text-decoration: none;
			}
		}

		.actionButtons {
			@include flex();

			gap: 70px;

			.actionButton {
				@include flex();

				gap: 12px;
				border: none;
				background: none;
				color: $paragraphTextColor;
				outline: none;
				cursor: pointer;
				transition: all 0.2s ease;
				transition: opacity 0.2s ease-out;

				path {
					transition: stroke 0.2s ease-out;
				}

				&:hover {
					color: #000;

					.favoriteIcon path {
						stroke: #000;
					}

					.shareIcon path {
						fill: #000;
					}
				}
			}
		}
	}
}

@include responsive(laptop) {
	.actionBarContainer .actionBar {
		gap: 14px;
		font-size: 15px;

		.nearbyText {
			display: none;
		}

		.favoriteButtonText,
		.shareButtonText {
			display: none;
		}

		.actionButtons {
			gap: 20px;
		}
	}
}

@include responsive(largeTablet) {
	.actionBarContainer .actionBar .actionLinks {
		gap: 40px;
	}
}

@include responsive(smallDevice) {
	.actionBarContainer .actionBar .actionLinks .tabs {
		display: none;
	}
}

@include responsive(extraSmallDevice) {
	.actionBarContainer .actionBar .actionLinks .tabs {
		display: none;
	}
}
