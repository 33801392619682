@import '../../utils/sass/variables.scss';

.container {
	margin-bottom: -4px;
	text-align: center;
}

.loader {
	display: inline-block;
	box-sizing: border-box;
	width: 30px;
	height: 30px;
	border: 5px solid;
	border-color: $darker-red transparent;
	border-radius: 50%;
	animation: rotation 1s linear infinite;
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
