@import '../../../utils/sass/mixins.scss';
@import '../../../utils/sass/variables.scss';

.signInContainer {
	@include flex();

	background-image:
		linear-gradient(
			to bottom,
			rgba(0, 0, 0, 0.4),
			rgba(0, 0, 0, 0.4)
		),
		url(
			'../../../assets/auth/signInBackground.png'
		);
	background-size: cover;

	p {
		color: #4a4a4a;
	}
}

.formContainer {
	width: 50%;
	min-width: 600px;
	margin: 80px 0;
	padding: 50px;
	border-radius: 8px;
	background-color: #fff;
}

.socialLoginButton {
	@include flex();

	position: relative;
	width: 100%;
	margin: 20px 0;
	padding: 20px;
	border: 1px solid #4a4a4a;
	border-radius: 6px;
	color: #4a4a4a;
	font-size: 18px;
	cursor: pointer;
	transition: background-color 0.2s ease-in;

	.icon {
		position: absolute;
		left: 20px;
	}

	&:hover {
		background-color: #f5f5f5;
	}
}

.divider {
	@include flex();

	margin-bottom: 33px;
	border-bottom: 1px solid #555;

	p {
		width: 90px;
		margin: 0;
		background-color: #fff;
		font-size: 22px;
		line-height: 13px;
		text-align: center;
		transform: translateY(50%);
	}
}

.passwordField {
	position: relative;
	cursor: pointer;

	.visibilityIcon {
		position: absolute;
		top: 17px;
		right: 20px;
	}
}

.validationError {
	color: #f00 !important;
}

.signInButton {
	@include primaryButton();
}

.link {
	@include underlinedLink();

	border-color: $native-link-color;
}

@media (max-width: 767px) {
	.formContainer {
		width: 80%;
		min-width: 80%;
	}
}

@media (max-width: 550px) {
	.formContainer {
		padding: 30px;
	}

	.socialLoginButton {
		padding: 20px;
		font-size: 14px;

		.icon {
			left: 10px;
			height: 40px;
		}
	}
}

@media (max-width: 400px) {
	.formContainer {
		width: 90%;
		padding: 30px 20px;
	}
}
