@import '../../../../utils/sass/mixins.scss';

.titleContainer {
	text-align: center;
}

.cards {
	margin: 3rem auto 0;
}

@include responsive(smallTablet) {
	.titleContainer {
		text-align: left;
	}
}
