@import '../../utils/sass/mixins.scss';

.buttonBody {
	@include primaryButton();

	padding: 8px 14px;
	white-space: nowrap;

	&.secondary {
		@include secondaryButton();
	}
}
