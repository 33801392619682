@import '../../utils/sass/mixins.scss';

.mainContainer {
	margin: 3rem 0 5rem;
	text-align: center;

	.secondaryText {
		width: 70%;
		margin: 0 auto;
	}

	.actionButtons {
		@include flex();

		gap: 1rem;
		margin-top: 2rem;
	}

	.homeLottie {
		width: 60%;
		margin: 0 auto 2rem;
		background-repeat: no-repeat;
	}
}

@include responsive(smallTablet) {
	.mainContainer {
		.secondaryText {
			width: 100%;
		}

		.actionButtons {
			flex-direction: column;

			button {
				width: 280px;
			}
		}

		.homeLottie {
			width: 100%;
		}
	}
}
