@import '../../../../utils/sass/variables.scss';
@import '../../../../utils/sass/mixins.scss';

.titleContainer {
	overflow: hidden;
	width: 100%;
	text-align: center;
}

.sectionContentContainer {
	margin: 3rem auto 0;
	text-align: center;
}

.statusChipsContainer {
	@include hideScrollBar();

	display: flex;
	overflow-x: scroll;
	-webkit-overflow-scrolling: touch;
	max-width: 100%;
}

.statusOptionChip {
	margin: 5px;
	padding: 8px 14px;
	border: 1px solid #979797;
	border-radius: 5px;
	color: #000;
	text-transform: capitalize;
	cursor: pointer;
	transition: all 0.2s ease-out;

	&:nth-of-type(1) {
		margin-left: 0;
	}

	&:hover {
		border-color: $primary-blue;
	}

	&.activeChip {
		border-color: $primary-blue;
		background-color: $background-blue;
	}
}

@include responsive(largeTablet) {
	.sectionContentContainer {
		width: 100%;
	}
}

.cards {
	width: 100%;
	min-height: 90vh;
	margin: 3rem auto 0;
}

@include responsive(laptop) {
	.statusChipsContainer {
		margin-bottom: 10px;
	}
}

@include responsive(smallTablet) {
	.titleContainer {
		text-align: left;
	}
}
