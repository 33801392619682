@import '../../../utils/sass/mixins.scss';

.editButton {
	padding: 0;
	border: none;
	border-bottom: 1px solid $primary-blue;
	background: none;
	color: $primary-blue;
	outline: none !important;
	font-size: 18px;
	transition: border 0.3s ease;

	&:hover {
		border-bottom-color: transparent;
	}
}

.infoContainer {
	@include flex(space-between);

	padding: 0.4rem 0;
	border-bottom: 1px solid #d9d9d9;

	.infoText,
	.infoValue {
		margin: 0;
		font-size: 18px;
	}

	.infoText {
		color: #4a4a4a;
	}

	.infoValue {
		max-width: 40%;
		color: #000;
		text-align: right;
	}
}

.paymentMethodsContainer {
	overflow: hidden;
	margin-top: 3rem;
	border: 1px solid #4a4a4a;
	border-radius: 4px;
	background-color: #f5f5f5;

	.cardPaymentContainer {
		@include flex(center, center, column);

		gap: 1rem;
		padding: 1rem 3.6rem;
		border-bottom: 1px solid #4a4a4a;
		opacity: 0.5;
	}

	.bankPaymentContainer {
		padding: 1rem 3.6rem;
		border-top: 1px solid #4a4a4a;
	}

	.customRadio {
		padding: 1rem;
		background-color: #fff;
		cursor: pointer;

		&.disabled {
			opacity: 0.5;
		}

		&:first-child {
			@include flex(space-between);

			border-bottom: 1px solid #4a4a4a;
		}

		input[type='radio'] {
			position: absolute;
			left: -99px;
		}

		input[type='radio'] + label {
			position: relative;
			margin: 0;
			padding-left: 40px;
			cursor: pointer;

			&::before {
				content: '';
				position: absolute;
				top: 3px;
				left: 0;
				width: 17px;
				height: 17px;
				border: 2px solid #311b92;
				border-radius: 50%;
				background: #fff;
			}

			&::after {
				content: '';
				position: absolute;
				top: 7px;
				left: 4px;
				width: 9px;
				height: 9px;
				border-radius: 50%;
				background: #311b92;
				opacity: 0;
				transition: transform 0.2s linear, opacity 0.2s linear;
				transform: scale(2);
			}
		}

		input[type='radio']:checked + label::after {
			opacity: 1;
			transform: scale(1.2);
		}
	}
}

.link {
	@include underlinedLink();

	border-color: $native-link-color;
}

.submitButtons {
	@include flex();

	gap: 10px;
	width: 94%;
	margin: 3rem auto 0;
}

@include responsive(smallDevice) {
	.submitButtons {
		width: 100%;
	}
}

@include responsive(smallTablet) {
	.editButton,
	.infoText,
	.infoValue {
		font-size: 16px;
	}

	.infoValue {
		max-width: 100% !important;
	}
}
